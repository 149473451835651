import { useEffect } from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useFetchWithLoader } from './utils'
import { useGlobalContext } from '../context/GlobalContext'
import { linkTextToSlug } from '../utils'

const usePageData = ({ ignoreSlug, overrideSlug } = {}) => {
  const fetchPageData = useFetchWithLoader()
  const { routes, setPageData, pageData, currentRoute, setCurrentRoute, loading } = useGlobalContext()
  const { slug } = useParams()
  const { path } = useRouteMatch()
  const route = routes.filter(({ url }) => url === path)[0]
  const sectionSlug = linkTextToSlug(route.linkText)
  const sectionData = pageData && pageData[sectionSlug]
  let currentPageData = sectionData

  useEffect(() => {
    if (!sectionData && !loading) {
      // console.log(`fetching ${sectionSlug} data`)
      fetchPageData({url:route.dataUrl}).then((data) => {
        setPageData({ ...pageData, [sectionSlug]: data })
      }).catch((err) => { console.log(err) })
    }
    setCurrentRoute(route)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ sectionData, loading ])

  if (sectionData && (overrideSlug || slug) && !ignoreSlug) {
    currentPageData = sectionData.filter(
      ({ slug: dataSlug }) => (overrideSlug || slug) === dataSlug
    )[0]
  }

  return { pageData: currentPageData, currentRoute, loading }
}

export default usePageData