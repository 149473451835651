import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { useRouteData } from './hooks'
import { Header } from './components/Header'
import { HomePage } from './components/HomePage'
import { Projects } from './components/Projects'
import { Stories } from './components/Stories'
import { Footer } from './components/Footer'
import { sortRoutesByDescendingPathLength } from './utils'

import './App.scss'

const generateRoutes = ({
  dataUrl,
  links,
  linkText,
  pageTitle,
  template,
  url
}, index) => {
  const Template = {
    _project: Projects,
    _text: Stories,
    _home: HomePage
  }[template]

  const isHome = template === '_home'
  return (
    <Route
      default={ isHome }
      exact={ !isHome }
      key={ `${url}_${index}` }
      path={ url }
      strict={ isHome }
    >
      <Template
        endpoint={ dataUrl }
        links={ links }
        linkText={ linkText }
        pageTitle={ pageTitle }
      />
    </Route>
  )
}

const App = () => {
  const { loading, routes } = useRouteData()
  console.log('App rendering')

  return (
    <Router>
      <Header />
      <section className="inner-wrapper">
        { loading
          ? ( <div> loading... </div> )
          : (
              <Switch>
                { routes && sortRoutesByDescendingPathLength(routes)
                  .map(generateRoutes)
                }
              </Switch>
            )
        }
      </section>
      <Footer />
    </Router>
  )
}

export default App