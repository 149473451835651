import React from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { ProjectCategoryCarousel } from './ProjectCategoryCarousel'
import { Project } from './Project'

import './projects.scss'


const Projects = ({ links, linkText, pageTitle }) => {
  const { category, slug } = useParams()
  const { pathname } = useLocation()
  const fallbackCategory = links && links[0].url.split(`${pathname}/`)[1]

  // document.title = pageTitle
  return (
    <div className="inner-content project">
      { slug && (
        <Project />
      ) }
      { !slug && category && (
        <ProjectCategoryCarousel
          categoryName={ category }
        />
      ) }
      { !slug && !category && (
        <ProjectCategoryCarousel
          categoryName={ fallbackCategory }
        />
      )}
    </div>
  )
}

export default Projects