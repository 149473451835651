import { createContext, useContext, useState } from 'react'

const GlobalContext = createContext({})

export const useGlobalContext = () => useContext(GlobalContext)

export const GlobalContextProvider = ({ children }) => {
  const [ loading, setLoading ] = useState()
  const [ pageData, setPageData ] = useState()
  const [ routes, setRoutes ] = useState()
  const [ currentRoute, setCurrentRoute ] = useState()

  return (
    <GlobalContext.Provider
      value={{
        pageData,
        setPageData,
        routes,
        setRoutes,
        currentRoute,
        setCurrentRoute,
        loading,
        setLoading
      }}
    >
      { children }
    </GlobalContext.Provider>
  )
}

export default GlobalContext