import { useEffect } from 'react'

import { useGetRoutesWithLoader } from './utils'
import { useGlobalContext } from '../context/GlobalContext'

const useRouteData = () => {
  const { routes, setRoutes, loading } = useGlobalContext()
  const fetchRoutes = useGetRoutesWithLoader()

  useEffect(() => {
    if (!routes && !loading) {
      console.log('fetching routes')
      fetchRoutes().then((data) => {
        setRoutes(data)
      }).catch((err) => { console.log(err) })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { routes, loading }
}

export default useRouteData