import React, { useRef } from 'react'
import Slider from 'react-slick'

import { Slide } from './Slide'
import { SliderArrow } from '../SliderArrow'
import { usePageData } from '../../hooks'

import "../../../node_modules/slick-carousel/slick/slick.css"
import './homepage.scss'


const Homepage = ({ endpoint, linkText, pageTitle }) => {

  const { loading, pageData } = usePageData()
  const slider = useRef(null)
  // let autoRotate = 0
  // let RtL = true

  // const swapSlides = () => {
  //   const tabletBreakPoint = 768
  //   const pageWidth = document.querySelector('body').offsetWidth

  //   if( slider.current && pageWidth > tabletBreakPoint){
  //     if( RtL ){
  //       slider.current.slickNext();
  //     }else{
  //       slider.current.slickPrev();
  //     }
  //   }
  // }

  // const handleMouseOver = (rightToLeft) => (event) => {
  //   console.log('hovered')
  //   RtL = rightToLeft
  //   stopSlides()
  //   swapSlides()
  //   autoRotate = setInterval(swapSlides, 2000)
  // }

  // const stopSlides = () => {
  //   clearInterval(autoRotate)
  // }

  const sliderProps = {
    className: 'slideshow',
    currentSlide: 1,
    enabled: true,
    arrows: true,
    cssEase: 'ease-in-out',
    dots: false,
    prevArrow: (
      <SliderArrow
        direction="prev"
      />
    ),
    nextArrow: (
      <SliderArrow
        direction="next"
      />
    ),
    method: {},
    speed: 1000,
  }
  document.title=pageTitle
  return (
      <div className="home">

        { loading && (
          <div>Loading...</div>
        )}

        { pageData && (
          <Slider ref={ slider } { ...sliderProps }>
            {
              pageData[0].slides.map(
                ({img, title, url}, index) => (
                  <Slide
                    img={img}
                    title={title}
                    url={url}
                    key={`${url}_${index}` }
                  />
                ))
            }
          </Slider>
        )}
      </div>
  )
}

export default Homepage