import { useGlobalContext } from '../context/GlobalContext'
import {
  generateFetchByRequest,
  getRoutes
} from '../io/fetchers'

const useLoader = (requestor) => {
  const { setLoading } = useGlobalContext()
  return (...requestParams) => new Promise((resolve, reject) => {
    setLoading(true)
    requestor(...requestParams)
      .then((data) => {
        setLoading(false)
        resolve(data)
      })
      .catch((err) => {
      setLoading(false)
      reject(err)
      })
  })
}

export const useFetchWithLoader = () =>
  useLoader(generateFetchByRequest)


export const useGetRoutesWithLoader = () =>
  useLoader(getRoutes)