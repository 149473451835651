import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'

import './Nav.scss'

const Nav = ({ isOpen, routes = [], handleMobileToggle }) => {
  const [openSubnav, setOpenSubnav] = useState('')
  const location = useLocation()
  useEffect(() =>{
    setOpenSubnav('')
  }, [location])
  return (
    <nav className={`primary-nav ${isOpen ? 'nav-open' : ''}`}>
      { routes.map(
        (route, index) => {
          if (!route.links || route.links.length === 1) {
            const href = route.url.replace('/:slug', '').replace(':category', 'all')
            return (
              <NavLink
                activeClassName="active"
                className="primary-nav__link"
                key={ `${route.url}_${index}` }
                to={ href }
                onClick={ handleMobileToggle }
              >
                { route.linkText }
              </NavLink>
            )
          }
          const subNavId = `${ route.linkText }_${index}`
          const hasActiveChild = matchPath(location.pathname, {
            path: route.url
          })

          return (
            <div
              className="sub-nav__wrapper"
              key={ `${route.url}_${index}` }
            >
              <button
                className={`sub-nav__trigger ${ hasActiveChild ? 'active' : ''}`}
                onClick={() => {
                  setOpenSubnav(openSubnav === subNavId ? '' : subNavId)
                }}
              >
                { route.linkText }
              </button>
              { openSubnav === subNavId &&
                <div className="sub-nav__links">
                  { route.links.map(({ url, linkText }, subIndex) => (
                      <NavLink
                        activeClassName="active"
                        className="sub-nav__link"
                        key={ `${url}_${subIndex}` }
                        to={ url }
                        onClick={ handleMobileToggle }
                      >
                        { linkText }
                      </NavLink>
                    ))
                  }
                </div>
              }
            </div>
          )}
        )
      }
    </nav>
  )
}
export default Nav