import React from 'react'

const Slide = ({ url, img, title, key }) => (
  <div
    className="item"
    key={key}
  >
    <a
        className="image"
        href={ url }
        style={{backgroundImage:`url(${ img })`}}
    >
        <span className="caption">
          { title }
        </span>
    </a>
  </div>
)

export default Slide