import React, { useEffect, useState } from 'react'

import { usePageData } from '../../../hooks'
import { ProjectThumbnail } from '../ProjectThumbnail'
import { ConditionalSlider } from '../../ConditionalSlider'


const ProjectCategoryCarousel = ({ categoryName }) => {
  const [categorySlides, setCategorySlides] = useState([])
  const { loading, pageData, currentRoute } = usePageData()

  useEffect(() => {
    const categorySet = pageData &&
      pageData.filter((projectData = {}) => {
        const projectCategory = projectData.category && projectData.category.toLowerCase
          ? projectData.category.replace('/', ' ') : ''
        return categoryName === 'all' ||
          projectCategory.toLowerCase() === categoryName
            .replace('+', ' ')
            .toLowerCase()
      })
    const categorySetName = categorySet && categorySet[0] && categorySet[0].category
    document.title = `${ currentRoute && currentRoute.pageTitle } : ${ categorySetName }`
    setCategorySlides(categorySet)
  }, [pageData, currentRoute, categoryName])

  return(
    <div className="category">
      { loading &&
        <div>loading...</div>
      }
      { !loading && categorySlides &&
        <ConditionalSlider>
          { categorySlides.map(
            ({ slug, projectThumb, title, imageDirectory, category }, index) => (
              <ProjectThumbnail
                key={`${slug}__${index}`}
                projectThumb={ projectThumb }
                slug={ slug }
                section={ category }
                thumbDirectory={ imageDirectory }
                title={ title }
              />
            )
          )}
        </ConditionalSlider>
      }
    </div>
  )
}

export default ProjectCategoryCarousel

