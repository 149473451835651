import React from 'react'
import './SliderArrow.scss'

const SliderArrow = ({ direction = 'prev', onClick, onMouseEnter, onMouseLeave }) => (
  <div
    onClick={ onClick }
    onMouseEnter={ onMouseEnter }
    onMouseLeave={ onMouseLeave }
    className={ `${direction}-arrow__wrapper` }
  >
    <div className={ `${direction}-arrow` } />
  </div>
)

export default SliderArrow