import React from 'react'

import { usePageData } from '../../../hooks'

import './story.scss'

const Story =  ({ slug }) => {
  const { pageData={} } = usePageData({ overrideSlug: slug })
  const { content, title } = pageData
  const joinedContent = content && content.constructor.name === 'Array' ? content.join('') : content
  return (
    <div className="text-container">
      <h2> { title } </h2>
      <div className="content" dangerouslySetInnerHTML={{ __html: joinedContent }} />
    </div>
  )
}

export default Story