import React from 'react'

const currentYear = (new Date()).getFullYear().toString();
const footerCopy = `Copyright ${currentYear} © Frank Repas Architecture PLLC`;

const Footer = () => (
  <footer className="wrapper">
    <hr />
    <small>
      { footerCopy }
    </small>
  </footer>
)

export default Footer
