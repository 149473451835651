import React from 'react'

import Slider from 'react-slick'
import { SliderArrow } from '../SliderArrow'

const ConditionalSlider = ({ children, ...props}) => {
  const sliderProps = {
    arrows: true,
    className: 'projects',
    cssEase: 'ease-in-out',
    currentSlide: 1,
    dots: true,
    enabled: true,
    method: {},
    nextArrow: (<SliderArrow direction="next" />),
    prevArrow: (<SliderArrow direction="prev" />),
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    variableWidth: false,
    // responsive : [
    //   {
    //     breakpoint: 1280,
    //     settings: {
    //       slidesToShow: 4,
    //       slidesToScroll: 1
    //     }
    //   },{
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //       dots: false
    //     }
    //   },{
    //     breakpoint : 680,
    //     settings : 'unslick'
    //   }
    // ]
  }
  return children && (children.length > sliderProps.slidesToShow)
    ? (<Slider { ...props } { ...sliderProps }>{ children }</Slider>)
    : (<div className={sliderProps.className}>{ children }</div>)
}

export default ConditionalSlider;