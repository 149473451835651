import React, { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './ProjectThumbnail.scss'

const ProjectThumbnail = ({ slug, projectThumb, title, thumbDirectory, section, details = {} }) => {
  const { pathname } = useLocation()
  const { client, status, category, content } = details
  const InnerWrapper = slug ? Link : Fragment
  const DescriptionTag = ({key, value}) => (
    <>
      <span className="project-item__details__key">{ key }:</span>
      <span className="project-item__details__value">{ value }</span>
    </>
  )
  return (
    <div
      className="project-item project"
    >
      <InnerWrapper to={`${pathname}/${slug}`}>
        <span
          className="image"
          style={{ backgroundImage:`url(${thumbDirectory}${projectThumb})` }}
        />
        <span className="project-item__title">
          <span className="project-item__category"><span>Projects :: </span>{ section }</span>
          <span className="project-item__name">{ title }</span>
        </span>
      </InnerWrapper>
    </div>
  )
}

export default ProjectThumbnail;