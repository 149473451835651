import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Nav } from '../Nav'
import { useGlobalContext } from '../../context/GlobalContext'

import './header.scss'

const HomeLinkText = 'Home'

const Header = ({ pageTitle='' }) => {
  const { routes } = useGlobalContext()
  const [ isOpen, setIsOpen ] = useState()

  const nonHomeRoutes = routes && routes.filter(
    (route) => route.linkText !== HomeLinkText
  )

  const homeRoute = routes && routes.filter(
    (route) => route.linkText === HomeLinkText
  )[0]

  const handleMobileToggle = () => {
    const tabletBreakPoint = 768
    const pageWidth = document.querySelector('body').offsetWidth

    if (pageWidth < tabletBreakPoint) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <header className="masthead inner-wrapper">
      <div className="nav-wrapper">
        { homeRoute && (
          <h1>
            <Link
              className="logo"
              to={ homeRoute.url }
              aria-label={ homeRoute.linkText }
            />
          </h1>
        )}
        <button
          aria-label="Menu"
          className="mobile-trigger"
          onClick={ handleMobileToggle }
        />
        <Nav
          isOpen={ isOpen }
          routes={ nonHomeRoutes }
        />
      </div>
      <hr />
    </header>
  )
}

export default Header
