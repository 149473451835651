import React, { useState } from 'react'

import { usePageData } from '../../../hooks'
import { ProjectThumbnail } from '../ProjectThumbnail'
import { ConditionalSlider } from '../../ConditionalSlider'


import './Project.scss'

const Project = () => {
  const { pageData } = usePageData()
  const [ detailsOpen, setDetailsOpen ] = useState()
  // const [ currentImage, setCurrentImage ] = useState()
  const {
    imageDirectory,
    thumbDirectory,
    title,
    images,
    client,
    status,
    category,
    content
  } = pageData || {}

  // return pageData ? (
  //   <pre>{ JSON.stringify(pageData, null, 4) }</pre>
  // ) : null

  // const imageSwap = (imagePath) => (event) => {
  //   setCurrentImage(imagePath)
  // }
  const toggleDetails = (event) => {
    setDetailsOpen(!detailsOpen)
  }

  return (
    <>
      <div className="project-detail">
        <div className={`project-detail__title ${detailsOpen ? 'project-detail__title--open' : ''}`}>
          <div className="project-detail__category"><span>Projects :: </span>{ category }</div>
          <h2 className="project-detail__name">{ title }</h2>
          <div className={`project-detail__info ${detailsOpen ? 'project-detail__info--open' : ''}`}>
            <div className="project-detail__stats">
              {client &&
                <h3><span className="project-detail__key">Client:</span>{ client }</h3>
              }
              {status &&
                <h3><span className="project-detail__key">Status:</span>{ status }</h3>
              }
              {category &&
                <h3><span className="project-detail__key">Category:</span>{ category }</h3>
              }
            </div>
              {content &&
                <div className="project-detail__description">{ content }</div>
              }
          </div>
          <button onClick={ toggleDetails } className="toggle-details">{ detailsOpen ? 'hide' : 'show'} details</button>
        </div>
        <div className="full-size">
          {/*{ images &&
            <img
              src={ `${imageDirectory}${(currentImage || images[0])}`}
              alt={ title }
            />
          }*/}
          <ConditionalSlider>
            { images &&
              images.map((image, index) => (
                <ProjectThumbnail
                  key={`${image}__${index}`}
                  projectThumb={ image }
                  section={ category }
                  thumbDirectory={ imageDirectory }
                  title={ title }
                />
              ))
            }
          </ConditionalSlider>
        </div>
  {/*      <ul className="thumbnails">
          { images &&
            images.map((image, index) => (
              <li>
                <button
                  className="project-thumbnail"
                  onClick={ imageSwap(image) }
                >
                  <img
                    src={`${thumbDirectory}${image}`}
                    alt={`${title} thumbnail ${index}`}
                  />
                </button>
              </li>
            ))
          }
          <li onClick={ toggleDetails } className="toggle-details">{ detailsOpen ? 'hide' : 'show'}<br /> details</li>
        </ul>*/}
      </div>
    </>
  )
}

export default Project
