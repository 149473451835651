import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { Story } from './Story'
import { usePageData } from '../../hooks'

import './stories.scss'

const buildStoryLink = ( pathname, currentSlug ) => ({ slug, title }, index) => {
  // covers edge case where section and slug are the same
  const isLanding = pathname.match(new RegExp(`^/${currentSlug}$`))
  const section = isLanding
    ? pathname
    : pathname.replace(new RegExp(`/${currentSlug}`), '')
  const linkTo = `${section}/${slug}`
  return (
    <li
      key={`${slug}__${index}`}
    >
      <Link
        className={currentSlug === pathname || currentSlug === slug ? 'active' : ''}
        to={linkTo}
      >
        { title }
      </Link>
    </li>
  )
}

const Stories = ({ endpoint, linkText, pageTitle }) => {
  const { loading, pageData } = usePageData({ ignoreSlug: true })
  const { pathname } = useLocation()
  const { slug } = useParams()
  const activeSlug = slug || (pageData && pageData[0].slug)

  document.title = pageTitle
  return (
    <div className="inner-content--stories">
        { loading && (
            <div>Loading...</div>
        )}

        <ul className="subnav">
          { pageData && pageData.map(buildStoryLink(pathname, activeSlug)) }
        </ul>

        <Story slug={ activeSlug } />

    </div>
  )
}

export default Stories
