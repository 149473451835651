import 'whatwg-fetch'

export const makeRequest = ({ url, params, method = 'GET', headers, body }) => new Promise( (resolve, reject) => {
  const queryString = params
    ? Object.keys(params).reduce(
        (query, key) => `${(query !== '?' ? '&' : '')}${escape(key)}=${escape(params[key])}`,
        '?'
      )
    : ''
  fetch(
    new Request(`${url}${queryString}`, { method, body, headers})
  ).then((response) => {
    response.json()
      .then((jsonBody) => {
        resolve(jsonBody)
      })
      .catch((error) => {
        reject(error)
      })
  }).catch((error) => {
    reject(error)
  })
})
